.body {
    height: 100vh;
    overflow-x: hidden;
    padding-top: 55px;
}
.nav-link.active {
    background-color: white;
    color: #8d2ad7;
}
.active a{
    color: #8d2ad7;
}
.right{
    color:white;
    font-size: 17px;
    font-weight: bolder;
}
.right:hover{
    font-size: 22px;
    color: white;
}
.nav-item:hover *{
    background-color: white;
    color: #8d2ad7;
    transition-duration: 0s;
}
.nav-item a{
    color:white;
    font-size: 19px;
    font-weight: bold;
    transition: 0s;
    
}
.nav-item{
    margin-bottom: 3px;
}
.w-sidebar {
    width: 230px;
    max-width: 230px;
   
}

.vh-100 {
    min-height: 100vh;
    
}

.row.collapse {
    margin-left: -230px;
    left: 0;
   
	transition: margin-left .15s linear;
}

.row.collapse.show {
    margin-left: 0 !important;
    
}

.row.collapsing {
    margin-left: -230px;
    left: -0.05%;
	transition: all .15s linear;
}

@media (max-width:768px) {

    .row.collapse,
    .row.collapsing {
        margin-left: 0 !important;
        left: 0 !important;
        overflow: visible;
    }
    
    .row > .sidebar.collapse {
        display: flex !important;
        margin-left: -100% !important;
        transition: all .3s linear;
        position: fixed;
        z-index: 1050;
        max-width: 0;
        min-width: 0;
        flex-basis: auto;
    }
    
    .row > .sidebar.collapse.show {
        margin-left: 0 !important;
        width: 100%;
        max-width: 100%;
        min-width: initial;
    }
    
    .row > .sidebar.collapsing {
        display: flex !important;
        margin-left: -10% !important;
        transition: all .3s linear !important;
        position: fixed;
        z-index: 1050;
        min-width: initial;
    }
    
}
